// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import mountComponents from "../utils/mountComponents"
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("bootstrap")
require("jquery.are-you-sure")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require.context("../images", true)

require("../stylesheets/application.scss")

// Make components available for react_component helper
const componentsContext = require.context(
  "../components",
  true,
  /(?<!(test|test\.[jt]sx?|\.scss|\.js))$/ // Exclude tests and scss files
)
mountComponents(componentsContext)
